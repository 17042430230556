.header-home {
  background-image: url("../images/bg-home.png");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Nunito", sans-serif;
  height: 100vh;
  overflow: hidden;
  background-position: left top;
}

.dotted-home-1 {
  border: 45px dotted white;
  width: 7.5rem;
  height: 12rem;
  opacity: 50%;
  position: static;
  margin-top: auto;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.header-container {
  display: flex;
  align-items: center;
}

.title-home {
  color: white;
  margin: 0 auto;
}

.header-chart > img {
  width: 30rem;
}

.intro-template {
  font-family: "Nunito", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.main-home .intro-template > h2 {
  color: #c81d39;
  font-size: 36px;
  font-weight: 900;
}

.intro-template > p {
  font-size: 24px;
}

.main-home {
  display: flex;
  flex-direction: column;
}

.intro-right {
  margin-left: auto;
}

.intro-right > h2,
.intro-right > span,
.intro-right > p {
  margin-left: auto;
  text-align: right;
}

.achievement-home {
  background-color: #f3efe7;
  display: flex;
}

.achievement-home > img {
  padding: 4rem 0;
  margin: 0 auto;
}

.container-card {
  display: flex;
  padding: 4rem 4rem;
  gap: 8rem;
  justify-content: center;
  font-family: "Nunito", sans-serif;
}

.card-home {
  color: #00405a;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  font-size: 24px;
}

.card-home a span {
  font-weight: 900;
}

.card-home img {
  margin-top: 1.5rem;
  width: 100%;
}

#podcast,
#spotify {
  width: 100%;
}

#spotify {
  margin-top: 2rem;
}

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 800px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #d9d9d9;
}

.slideshowDot.active {
  background-color: #4b4b4b;
}

.slide img {
  width: 100%;
  padding: 1rem;
  border-radius: 20px;
}

.event-home {
  margin-top: 1rem;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 24px;
}

.event-home > h2 {
  color: white;
  margin-bottom: 0.5rem;
}

.button-direct {
  padding: 10px 60px;
  font-family: "Nunito", sans-serif;
  color: white;
  font-weight: bold;
  background-color: #00405a;
  font-size: 20px;
  border-radius: 80px;
  border: 0;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.bg-card-red {
  background: linear-gradient(180deg, rgba(200, 29, 57, 0) 0%, #c81d39 100%);
  border: 1px solid #cdcdcd;
}

.bg-card-blue {
  background: linear-gradient(180deg, #ffffff 0%, #00405a 100%);
  border: 1px solid #cdcdcd;
}
