.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.paginationItem {
  background-color: white;
  border: none;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  height: 45px;
  width: 45px;
  transform: translate(-50%, -50%);
  font-family: "Inter", sans-serif;
  font-weight: bold;
}

.paginationItem span:hover {
  color: red;
}
.prev,
.next {
  border: none;
  padding: 10px;
  color: blue;
  cursor: pointer;
  width: 3rem;
}

.paginationItem.active {
  border: none;
  background-color: #c81d39;
  pointer-events: none;
  color: white;
}

.prev.disabled,
.next.disabled {
  display: none;
}

.header-documentation {
  text-align: center;
  padding: 4rem 0;
  font-family: "Nunito", sans-serif;
  font-size: 32px;
}

.header-documentation > hr {
  width: 20%;
  margin: 0 auto;
  background-color: #f8c980;
  border: 0;
  height: 0.4rem;
}

.main-documentation {
  padding: 6rem;
  padding-top: 0;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
}

.dataContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.post {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.post > p {
  margin-top: 1rem;
}

.post > img {
  width: 25rem;
}

.bg-achievement {
  background-image: url("../images/bg-achievement.png");
  background-repeat: no-repeat;
  background-size: cover;
}
