.education-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Nunito", sans-serif;
}

.education-info > h2 {
  margin-top: 150px;
  margin-bottom: 3rem;
  color: #00405a;
  font-size: 30px;
}

.education-description-wrapper {
  width: 75vw;
  font-size: 24px;
  display: flex;
  text-align: center;
  margin: 1rem 0 5rem 0;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.card-thumbnail {
  max-height: 212px;
}

.card-thumbnail-container {
  width: 300px;
  height: 212px;
  background-color: #f3efe7;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.card-button {
  margin-top: 1rem;
  padding: 0.5rem 1.2rem;
  background: #c81d39;
  border-radius: 5px;
  border: none;
  font-family: "Nunito", sans-serif;
  font-size: 13px;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.title {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
}

.title-container {
  margin-top: 1rem;
}

.title-container > p {
  font-size: 0.8em;
}

.header-education {
  background-image: url("../images/bg-education.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  background-position: left top;
}

.header-education p {
  font-size: 96px;
  text-align: center;
  padding-top: 15rem;
  padding-bottom: 21.5rem;
  font-family: "Nunito", sans-serif;
}

.education-container {
  font-family: "Nunito", sans-serif;
}

.education-container h1 {
  text-align: center;
}

.button-container > button {
  padding: 1rem 2rem;
  border: 0;
  font-weight: bold;
  font-size: 20px;
}

#education-button-active {
  background-color: #f3efe7;
}

.pdf-container {
  margin-top: 3rem;
  height: 100vh;
  font-family: "Nunito", sans-serif;
}

.pdf-container h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.pdf-container p {
  margin-top: 3rem;
  text-align: justify;
  font-size: 18px;
}
