.footer {
  display: flex;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  color: white;
  font-weight: bold;
  background-color: #c81d39;
  z-index: 2;
  position: relative;
}

.footer-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2rem 0;
}

.footer-title > img {
  width: 3rem;
}

.footer-nav {
  text-align: center;
}

.footer-nav li {
  display: inline;
  margin: 0 20px;
}

.footer-nav a {
  text-decoration: none;
  color: white;
}

.footer-nav a:hover {
  color: red;
}

.footer-container > p {
  text-align: center;
  margin-top: 2rem;
}

.logos {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
}

.logo-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto auto;
}

.sidebox-left,
.sidebox-right {
  background-color: #00405a;
  height: 3rem;
}

.sidebox-left {
  border-top-right-radius: 135px;
}

.sidebox-right {
  border-top-left-radius: 135px;
}
