.tv-header {
  width: 100%;
}

.tradingview-main {
  background-image: url("../images/bg-divisi.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Nunito", sans-serif;
}

.tradingview-btn {
  padding: 0.6em 1em;
  background-color: #c81d39;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}
