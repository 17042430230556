.contact-us {
  background-color: white;
  font-family: "Nunito", sans-serif;
}

.contact-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 10px;
}

.contact-container img {
  width: 2em;
  height: 1.8em;
  margin-top: 5px;
}

.contacts {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.contact-list {
  display: flex;
  flex-direction: row;
  gap: 40px;
  color: white;
}

.zindex {
  z-index: -1;
}
.location {
  margin-top: 6em;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  padding-bottom: 6em;
  color: #c81d39;
  z-index: 3;
}

.location img {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.location > p {
  margin-top: 1rem;
}

.contacts {
  padding: 10px;
}
.contacts a {
  text-decoration: none;
  color: white;
}

.contacts a:hover {
  color: red;
}

.contact-title {
  padding-top: 5%;
  font-weight: bold;
  margin-bottom: 20px;
}

.dotted-top,
.dotted-bottom {
  border: 15px dotted white;
  width: 2.5rem;
  height: 2.5rem;
  opacity: 50%;
  position: static;
}

.dotted-bottom {
  margin-left: auto;
}

.bg-contact {
  background: url("../images/bg-contact.png");
  height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  z-index: 2;
  background-position: left top;
}

.bg-red-linear {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    #c81d39 100%
  );
}

.bg-blue-linear {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    #00405a 100%
  );
}
