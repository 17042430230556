.podcast-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 2rem;
}

.about-podcast {
  display: flex;
  flex-direction: row;
}

.dotted-podcast {
  border: 30px white;
  border-style: dotted none none none;
  letter-spacing: 50px;
  width: 5rem;
  opacity: 50%;
  margin-left: auto;
}

.podcast-header-1,
.podcast-header-2 {
  font-family: "Nunito", sans-serif;
  margin: 0 auto;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.podcast-header-1 {
  width: 50%;
}

.podcast-header-1 {
  background-color: #f8c980;
  border-radius: 20px;
  margin-bottom: 3rem;
  margin-top: 2rem;
  padding-left: 2rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.podcast-header-1 > h1 {
  color: #00405a;
  font-weight: bold;
  font-size: 40px;
}

.podcast-header-2 > h2 {
  color: #00405a;
  font-weight: bold;
  font-size: 36px;
}

.about-podcast > h3 {
  font-weight: bold;
  padding-top: 0.5rem;
}

.podcast-header-1 > p {
  line-height: 2rem;
  padding-right: 2rem;
  text-align: justify;
}

.podcast-header-2 img {
  width: 15rem;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.follow-now {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.button-follow {
  padding: 10px 60px;
  font-family: "Nunito", sans-serif;
  color: black;
  font-weight: bold;
  background-color: #f3efe7;
  font-size: 20px;
  border-radius: 80px;
  border: 0;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-follow:active {
  box-shadow: 0px 0px 0px;
  transform: translateY(4px);
}

.podcast-header-2 a {
  margin-top: auto;
  border-radius: 80px;
}

.podcast-header-2 {
  padding-bottom: 3rem;
}

.podcast-main {
  font-family: "Nunito", sans-serif;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 40px;
}

.podcast-main > h1 {
  font-size: 30px;
}

.search-bar {
  display: flex;
  flex-direction: row;
  font-family: "Merriweather Sans", sans-serif;
  font-size: 13px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 15px 20px;
  align-items: center;
  margin: 0 auto;
  border-radius: 10px;
  border: 0;
  gap: 10px;
  margin-top: 1.5rem;
  background-color: #f3efe7;
}

.search-bar > img {
  width: 30px;
}

.search-bar > input {
  font-size: 20px;
  border: 0;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  background-color: #f3efe7;
}

.search-bar > input:focus {
  outline: none;
}

.podcast-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 40px;
}

.spotify-list {
  background-color: #c81d39;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  color: white;
}

.spotify-list-active {
  background-color: #f5f5f5;
  padding: 1.5rem 2rem;
  border-radius: 20px;
}

.spotify-list > h2 > span {
  font-weight: 300;
}

.podcast-list iframe {
  width: 100%;
  border: 0;
  border-radius: 20px;
  margin-top: 1.5rem;
}

.spotify-drop {
  display: flex;
  flex-direction: row;
}

.spotify-drop > img {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
}

.bg-podcast {
  background-image: url("../images/bg-podcast.png");
  background-repeat: no-repeat;
  background-size: cover;
}
