.nav {
  background-color: #c81d39;
  position: sticky;
  top: 0;
  z-index: 999;
}

.nav-icon {
  display: block;
  float: left;
  width: 13%;
}

.nav-link {
  text-decoration: none;
  font-family: "Nunito", sans-serif;
  color: white;
  padding-top: 25px;
  padding-bottom: 25px;
  cursor: pointer;
  text-align: center;
}

.nav .nav-links {
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #00405a;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #c81d39;
}

.nav-link li:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: #fff;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.nav-link li:hover:before {
  visibility: visible;
  width: 100%;
}
