.header-profile {
  background-image: url("../images/bg-profile.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: "Nunito", sans-serif;
}

.main-profile {
  font-family: "Nunito", sans-serif;
}

.linearbgright {
  background: linear-gradient(
    270deg,
    rgba(46, 94, 134, 0.88) 9.86%,
    #c81d39 65.46%
  );
  border-top-left-radius: 113.5px;
  border-bottom-left-radius: 113.5px;
  padding: 1rem 0;
}

.linearbgleft {
  background: linear-gradient(
    270deg,
    rgba(46, 94, 134, 0.88) 9.86%,
    #c81d39 65.46%
  );
  border-top-right-radius: 113.5px;
  border-bottom-right-radius: 113.5px;
  padding: 1rem 0;
}

.main-profile li {
  font-size: 24px;
}

.organogram {
  text-align: center;
  color: #cb5542;
  padding-top: 4rem;
}

.organogram img {
  width: 80%;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.outlinetext {
  text-shadow: white 0px 0px 10px, white 0px 0px 10px, white 0px 0px 10px,
    white 0px 0px 10px, white 0px 0px 10px, white 0px 0px 10px;
}
