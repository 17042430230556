.header-event {
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 26px;
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: white;
}

.bg-divisi {
  background-image: url("../images/bg-divisi.png");
  background-size: cover;
}
.header-event h1 {
  font-size: 80px;
  margin-bottom: 5rem;
  text-shadow: #00405a 0px 0px 10px, #00405a 0px 0px 10px, #00405a 0px 0px 10px,
    #00405a 0px 0px 10px, #00405a 0px 0px 10px, #00405a 0px 0px 10px;
}

.divisi-event h1 {
  text-align: center;
  font-family: "Nunito", sans-serif;
  background: linear-gradient(
    270deg,
    #c81d39 2.66%,
    rgba(46, 94, 134, 0.88) 61.21%
  );
  border-radius: 113.5px;
  margin: 0 auto;
  top: -50px;
  position: absolute;
  overflow-x: visible;
}

.event {
  display: flex;
}

.event img {
  width: 23rem;
  height: 15rem;
  margin-top: 3rem;
}

.event-main {
  margin: 0 auto;
}

.img-left img {
  margin-left: auto;
}

.event-detail-left {
  padding: 4rem 0;
}

.event-detail-right {
  padding: 4rem 0;
}
